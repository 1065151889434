import React from "react";

const MarkerTentamus = ({position, markerSize, joinedIn, idx, animateSpeed}) => {

  const markerRef = React.useRef(null);
  const [hasJoined, setHasJoined] = React.useState(animateSpeed ? 0 : 1);

  React.useEffect(() => {
    setTimeout(()=>{
      setHasJoined(1);
    }, (joinedIn - 2011)*animateSpeed+animateSpeed);
  }, [joinedIn, animateSpeed]);

  React.useEffect(() => {
    if (animateSpeed && hasJoined && markerRef.current) {
      markerRef.current.animate([
        { transform: 'scale(0)' },
        { transform: 'scale('+(0.4 / position)+')' }
      ], {
        duration: animateSpeed * 1.5,
        fill: 'forwards',
        easing: 'cubic-bezier(0, 0, 0.05, 1)'
      });
    }
  }, [hasJoined, position, animateSpeed]);

  return (
    <g 
      ref={markerRef} 
      style={{
        zIndex: idx,
        transform: "scale("+(0.4 / position * hasJoined)+")",
        transition: "transform "+(animateSpeed * 1.5)+"ms cubic-bezier(0, 0, 0.05, 1)"
      }} 
      className={hasJoined ? 'locationMarker locationMarker--joined' : 'locationMarker'} 
      transform={"scale("+(0.4 / position * hasJoined)+")"}
    >
      <g transform={"translate(-"+(markerSize.width / 2)+", -"+markerSize.height+")"}>
        <g>
          <path fill="#00cc7a" d="M10,0C4.5,0,0,4.5,0,10c0,10,10,20,10,20s10-10,10-20C20,4.5,15.5,0,10,0z"/>
          <path fill="#FFFFFF" d="M7.9,15.6c-1.4,0.1-1.9,0.7-2.1,1.2C7,17.6,8.5,18,10,18c1.7,0,3.3-0.5,4.6-1.4
            C12.9,16.2,9.7,15.5,7.9,15.6z"/>
          <path fill="#FFFFFF" d="M12.4,12c1.1,1.7,2.5,3.1,3.2,3.7c0.8-0.8,1.4-1.7,1.8-2.7C16.4,12.5,14.3,11.7,12.4,12z"/>
          <path fill="#FFFFFF" d="M10,2L10,2L10,2c0.7,0,1.4,0.1,2,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-1,0.9-2.2,2.6-1.6,5.4c0.7,3.3,3,6,4.5,7.5
            c-1.6-0.4-4.1-0.9-5.9-0.9l0,0c-0.2,0-0.5,0-0.7,0c-1.3,0.1-2.1,0.5-2.6,1.3c0,0.1-0.1,0.1-0.1,0.2C3.1,14.8,2,12.5,2,9.9
            C2,5.6,5.6,2,10,2"/>
          <path fill="#FFFFFF" d="M16.1,11.8c0-0.2,0.1-0.4,0.1-0.7c0-2.2-1.8-4-4-4c-0.6,0-1.1,0.1-1.6,0.3c0,0.2,0,0.3,0.1,0.5
            c0.2,1.2,0.7,2.2,1.2,3.2C13.4,10.9,15,11.4,16.1,11.8z"/>
        </g>
      </g>
    </g>
  );
};

export default MarkerTentamus;
