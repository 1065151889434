import React from "react";

const Timeline = ({hasData, speed, started}) => {

  const years = Array.from({length: (new Date().getFullYear()) - 2010}, (_, i) => i + 2011);

  const [isRunning, setIsRunning] = React.useState(false);
  React.useEffect(() => {
    if (!hasData || !started) return;
    setTimeout(() => { 
      setIsRunning(true);
      setTimeout(() => { window.dispatchEvent(new CustomEvent('ws_cmp_simple-map__done')) }, years.length * speed);
    }, speed);
  }, [hasData, speed, started, years]);

  if (!hasData) return null;

  return (
    <ul
      className={"timeline" + (isRunning ? ' timeline--running' : '')}
      style={{
        animationDuration: (years.length * speed) + 'ms',
        transitionDuration: (years.length * speed) + 'ms'
      }}
    >{
      years.map((year) => (
        <li key={year}>{year}</li>
      ))
    }</ul>
  );
};

export default Timeline;
