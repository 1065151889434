import React from 'react';
import preparePosts from './preparePosts';
import MapChart from "./MapChart"; // https://www.react-simple-maps.io/docs/getting-started/
import Timeline from './Timeline';

const args = JSON.parse(localStorage.getItem('ws-cmp-simple-map--args'));

const App = () => {

  const [markersData, setMarkersData] = React.useState([]);
  const [started, setStarted] = React.useState(!args.timeline);

  const startUp = () => setStarted(true);
  
  React.useEffect(() => {

    const rootElement = document.getElementById('ws-cmp-simple-map');
    rootElement.addEventListener('ws-cmp-simple-map--start', startUp);

    fetch(args.json)
      .then(res => res.json())
      .then((result) => {
        result.posts = result.posts.map((post) => ({...post, date: post.date.split('+')[0]})); // convert date for safari (only?)
        window.dispatchEvent(new CustomEvent('ws_cmp_simple-map__data_loaded'));
        setMarkersData(preparePosts(result.posts, args));
      }, (error) => { console.log(error); })

      return () => rootElement.removeEventListener('ws-cmp-simple-map--start', startUp);

  }, []);

  return (
    <>
      <MapChart
        markersData={started ? markersData : []}
        style={args.style || {
          fill: '#006341',
          stroke:"#006341",
          strokeWidth:0.5,
        }}
        markerSize={args.marker_size}
        animateSpeed={args.timeline}
      />
      { args.timeline &&
        <Timeline hasData={markersData.length} speed={args.timeline} started={started} />  
      }
      
    </>
  );

}

export default App;