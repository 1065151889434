import dateFormat from 'dateformat';

const resolvePath = (object, path, defaultValue) => {

  var functionCheck = path.match(/(.+?)\((.+?),(.+?)\)/);
  if (functionCheck) {
    if (functionCheck.length !== 4) throw new Error('resolvePath: Faulty function patten');
    path = functionCheck[2];
  } 

  let result = path
    .split('?').reduce((prev, cur) => (
      typeof prev !== 'undefined' && prev.length ?
        prev :
        cur.split(':')[0].split('.').reduce((prev, cur) => 
          prev ?
            ( typeof prev[cur] === 'undefined' ?
              defaultValue : 
              prev[cur]
            ) :
            defaultValue
          , object
        )
    ), '');

  // : —> join array of objects
  if (typeof result === 'object' && path.split(':').length === 2)
    return result.map((array) => array[path.split(':')[1]]).join(', ');

  // join array
  if (typeof result === 'object' && typeof result.join === 'function')
    return result.join(', ');

  // apply function
  if (functionCheck) {
    switch (functionCheck[1]) {

        case 'date':
          result = dateFormat(new Date(result), functionCheck[3])
          break;

        default:
          throw new Error('resolvePath: Unknown function type');

    }
  }

  return result;

}

export default resolvePath;