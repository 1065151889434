import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import CustomZoomableGroup from "./CustomZoomableGroup";
import topology from "./topologies/world-50m-simplified.json"; // TODO: allow to select world-50m-simplified.json through args.resolution

import MarkerTentamus from './MarkerTentamus';

const MapChart = ({ markersData, style, markerSize, animateSpeed }) => {
  return (
    <ComposableMap
      width={775}
      height={775 * 0.505} // this factor is valid for projection geoNaturalEarth1
      projection="geoNaturalEarth1"
      projectionConfig={{
        scale: 165
      }}
    >
      <CustomZoomableGroup center={[12, 11.5]} maxZoom={16}>
        {position => (
          <>
            <Geographies geography={topology}>
              {({ geographies }) =>
                geographies
                  .filter(d => d.properties.REGION_UN !== "Antarctica")
                  .map(geo => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      {...style}
                      style={{default:{outline:'none'},hover:{outline:'none'},pressed:{outline: 'none'}}}
                    />
                  ))
              }
            </Geographies>
            {markersData.map(({ id, coordinates, text, joinedIn }, idx) => (
              <Marker key={id} coordinates={coordinates}>
                <MarkerTentamus idx={idx} position={position.k} markerSize={markerSize} joinedIn={joinedIn} animateSpeed={animateSpeed} />
              </Marker>
            ))}
          </>
        )}
      </CustomZoomableGroup>
    </ComposableMap>
  );
};

export default MapChart;
