import resolvePath from './resolvePath';

function preparePosts(posts, args) {
  let markers = posts.filter((post) => (
      resolvePath(post, args.lat_lng, false) && post.fields.joined_in
    )).map((post) => {
      const coordinates = resolvePath(post, args.lat_lng, false).split(',');
      return {
        'id': post.id,
        'text': post.title,
        'lon': parseFloat(coordinates[0]),
        'coordinates' : [ parseFloat(coordinates[1]), parseFloat(coordinates[0]) ], // lon, lat
        'joinedIn' : parseInt(post.fields.joined_in)
      }
    }).sort((a, b) => (a.lon < b.lon) ? 1 : -1);
  return markers;
}

export default preparePosts;